import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom';
import { Container, Nav, Navbar } from 'react-bootstrap';
import Books from './Books';
import Users from './Users';
import LearningPaths from './LearningPaths';
import SummaryOfTheDay from './SummaryOfTheDay';
import Login from './Login';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

const App = () => {
  const [auth, setAuth] = useState(true);
  const [name,  setName]= useState("Thomas");

  useEffect(() => {

    document.title = 'Readana - Back Office';

    const token = localStorage.getItem('token');
    const nameStorage = localStorage.getItem('name');
    if (token) {
      setName(nameStorage);
      setAuth(true);
    }
    setName(nameStorage);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    setAuth(true);
  };

  return (
    <Router>
      <div className="App">
        <Navbar bg="dark" variant="dark" expand="lg" sticky="top">
          <Container>
            <Navbar.Brand as={Link} to="/">Readana</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link as={Link} to="/books">Books</Nav.Link>
                <Nav.Link as={Link} to="/users">Utilisateurs</Nav.Link>
                <Nav.Link as={Link} to="/learning-paths">Chemins d'Apprentissages</Nav.Link>
                <Nav.Link as={Link} to="/summary-of-the-day">Résumé du Jour</Nav.Link>
              </Nav>
              {auth && (
                <Nav>
                  Hello {name},
                  <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
                </Nav>
              )}
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <Container>
          <Routes>
            <Route path="/" element={auth ? <Home /> : <Navigate to="/login" />} />
            <Route path="/books" element={auth ? <Books /> : <Navigate to="/login" />} />
            <Route path="/users" element={auth ? <Users /> : <Navigate to="/login" />} />
            <Route path="/learning-paths" element={auth ? <LearningPaths /> : <Navigate to="/login" />} />
            <Route path="/summary-of-the-day" element={auth ? <SummaryOfTheDay /> : <Navigate to="/login" />} />
            <Route path="/login" element={<Login setAuth={setAuth} />} />
          </Routes>
        </Container>
      </div>
    </Router>
  );
};

const Home = () => {
  return (
    <div>
      <h1>Home Page</h1>
    </div>
  );
};

export default App;
