import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Table, Form, Modal as BootstrapModal, Container, Row, Col } from 'react-bootstrap';
import env from 'react-dotenv';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState({ name: '', email: '', role: 'Basic' });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editUser, setEditUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(env.API_URL+'/users');
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
      setUsers([
        { id: 1, name: 'John Doe', email: 'john.doe@example.com', role: 'Admin' },
        { id: 2, name: 'Jane Smith', email: 'jane.smith@example.com', role: 'Editeur' }
      ]);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUser({ ...newUser, [name]: value });
  };

  const handleAddUser = async () => {
    try {
      const response = await axios.post(env.API_URL+'/users', newUser);
      setUsers([...users, response.data]);
      setNewUser({ name: '', email: '', role: 'Basic' });
    } catch (error) {
      console.error('Error adding user:', error);
    }
  };

  const openModal = (user) => {
    setEditUser(user);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setEditUser(null);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditUser({ ...editUser, [name]: value });
  };

  const handleSaveEdit = async () => {
    try {
      const response = await axios.put(env.API_URL+`/users/${editUser.id}`, editUser);
      setUsers(users.map((user) => (user.id === editUser.id ? response.data : user)));
      closeModal();
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  const handleDeleteUser = async (id) => {
    try {
      await axios.delete(env.API_URL+`/users/${id}`);
      setUsers(users.filter((user) => user.id !== id));
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredUsers = users.filter((user) =>
    user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.role.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Container className="mt-5">
      <Row>
        <Col>
          <h1 className="mb-4">Liste des Utilisateurs</h1>
          <Form.Control
            type="text"
            placeholder="Rechercher un utilisateur..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="mb-4"
          />
          <Table striped bordered hover>
            <thead>
            <tr>
              <th>Nom</th>
              <th>Email</th>
              <th>Rôle</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>
                <Form.Control
                  type="text"
                  name="name"
                  value={newUser.name}
                  onChange={handleInputChange}
                  placeholder="Nom"
                />
              </td>
              <td>
                <Form.Control
                  type="email"
                  name="email"
                  value={newUser.email}
                  onChange={handleInputChange}
                  placeholder="Email"
                />
              </td>
              <td>
                <Form.Control as="select" name="role" value={newUser.role} onChange={handleInputChange}>
                  <option value="Admin">Admin</option>
                  <option value="Editeur">Editeur</option>
                  <option value="Abonné">Abonné</option>
                  <option value="Basic">Basic</option>
                </Form.Control>
              </td>
              <td>
                <Button variant="success" onClick={handleAddUser}>➕</Button>
              </td>
            </tr>
            {filteredUsers.map((user, index) => (
              <tr key={index}>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>{user.role}</td>
                <td>
                  <Button variant="warning" onClick={() => openModal(user)} className="me-2">✏️</Button>
                  <Button variant="danger" onClick={() => handleDeleteUser(user.id)}>❌</Button>
                </td>
              </tr>
            ))}
            </tbody>
          </Table>
        </Col>
      </Row>

      <BootstrapModal show={modalIsOpen} onHide={closeModal}>
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>Modifier l'Utilisateur</BootstrapModal.Title>
        </BootstrapModal.Header>
        <BootstrapModal.Body>
          {editUser && (
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Nom</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={editUser.name}
                  onChange={handleEditChange}
                  placeholder="Nom"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={editUser.email}
                  onChange={handleEditChange}
                  placeholder="Email"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Rôle</Form.Label>
                <Form.Control as="select" name="role" value={editUser.role} onChange={handleEditChange}>
                  <option value="Admin">Admin</option>
                  <option value="Editeur">Editeur</option>
                  <option value="Abonné">Abonné</option>
                  <option value="Basic">Basic</option>
                </Form.Control>
              </Form.Group>
            </Form>
          )}
        </BootstrapModal.Body>
        <BootstrapModal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Annuler
          </Button>
          <Button variant="primary" onClick={handleSaveEdit}>
            Enregistrer
          </Button>
        </BootstrapModal.Footer>
      </BootstrapModal>
    </Container>
  );
};

export default Users;
