import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Table, Form, Modal as BootstrapModal, Container, Row, Col } from 'react-bootstrap';
import env from 'react-dotenv';

const LearningPaths = () => {
  const [learningPaths, setLearningPaths] = useState([]);
  const [books, setBooks] = useState([]);
  const [newPath, setNewPath] = useState({ name: '', description: '', books: [] });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editPath, setEditPath] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchLearningPaths();
    fetchBooks();
  }, []);

  const fetchLearningPaths = async () => {
    try {
      const response = await axios.get(env.API_URL+'/learning-paths');
      setLearningPaths(response.data);
    } catch (error) {
      console.error('Error fetching learning paths:', error);
      setLearningPaths([{name: "Science", description: "Ceci est un chemin", books: []}])
    }
  };

  const fetchBooks = async () => {
    try {
      const response = await axios.get(env.API_URL+'/books');
      setBooks(response.data);
    } catch (error) {
      console.error('Error fetching books:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewPath({ ...newPath, [name]: value });
  };

  const handleAddPath = async () => {
    try {
      const response = await axios.post(env.API_URL+'/learning-paths', newPath);
      setLearningPaths([...learningPaths, response.data]);
      setNewPath({ name: '', description: '', books: [] });
    } catch (error) {
      console.error('Error adding learning path:', error);
    }
  };

  const openModal = (path) => {
    setEditPath(path);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setEditPath(null);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditPath({ ...editPath, [name]: value });
  };

  const handleAddBookToPath = (book) => {
    if (!editPath.books.includes(book.id)) {
      setEditPath({ ...editPath, books: [...editPath.books, book.id] });
    }
  };

  const handleSaveEdit = async () => {
    try {
      const response = await axios.put(env.API_URL+`/learning-paths/${editPath.id}`, editPath);
      setLearningPaths(learningPaths.map((path) => (path.id === editPath.id ? response.data : path)));
      closeModal();
    } catch (error) {
      console.error('Error updating learning path:', error);
    }
  };

  const handleDeletePath = async (id) => {
    try {
      await axios.delete(env.API_URL`/learning-paths/${id}`);
      setLearningPaths(learningPaths.filter((path) => path.id !== id));
    } catch (error) {
      console.error('Error deleting learning path:', error);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredPaths = learningPaths.filter((path) =>
    path.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    path.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Container className="mt-5">
      <Row>
        <Col>
          <h1 className="mb-4">Chemins d'Apprentissages</h1>
          <Form.Control
            type="text"
            placeholder="Rechercher un chemin..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="mb-4"
          />
          <Table striped bordered hover>
            <thead>
            <tr>
              <th>Nom</th>
              <th>Description</th>
              <th>Livres</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>
                <Form.Control
                  type="text"
                  name="name"
                  value={newPath.name}
                  onChange={handleInputChange}
                  placeholder="Nom"
                />
              </td>
              <td>
                <Form.Control
                  type="text"
                  name="description"
                  value={newPath.description}
                  onChange={handleInputChange}
                  placeholder="Description"
                />
              </td>
              <td></td>
              <td>
                <Button variant="success" onClick={handleAddPath}>➕</Button>
              </td>
            </tr>
            {filteredPaths.map((path, index) => (
              <tr key={index}>
                <td>{path.name}</td>
                <td>{path.description}</td>
                <td>{path.books.length}</td>
                <td>
                  <Button variant="warning" onClick={() => openModal(path)} className="me-2">✏️</Button>
                  <Button variant="danger" onClick={() => handleDeletePath(path.id)}>❌</Button>
                </td>
              </tr>
            ))}
            </tbody>
          </Table>
        </Col>
      </Row>

      <BootstrapModal show={modalIsOpen} onHide={closeModal}>
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>Modifier le Chemin d'Apprentissage</BootstrapModal.Title>
        </BootstrapModal.Header>
        <BootstrapModal.Body>
          {editPath && (
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Nom</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={editPath.name}
                  onChange={handleEditChange}
                  placeholder="Nom"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  name="description"
                  value={editPath.description}
                  onChange={handleEditChange}
                  placeholder="Description"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Ajouter des Livres</Form.Label>
                <Form.Control as="select" onChange={(e) => handleAddBookToPath(books.find(book => book.id === e.target.value))}>
                  <option value="">Sélectionner un livre</option>
                  {books.map((book) => (
                    <option key={book.id} value={book.id}>{book.title}</option>
                  ))}
                </Form.Control>
              </Form.Group>
              <div>
                <h5>Livres dans ce chemin:</h5>
                <ul>
                  {editPath.books.map((bookId) => {
                    const book = books.find((b) => b.id === bookId);
                    return <li key={bookId}>{book ? book.title : 'Livre inconnu'}</li>;
                  })}
                </ul>
              </div>
            </Form>
          )}
        </BootstrapModal.Body>
        <BootstrapModal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Annuler
          </Button>
          <Button variant="primary" onClick={handleSaveEdit}>
            Enregistrer
          </Button>
        </BootstrapModal.Footer>
      </BootstrapModal>
    </Container>
  );
};

export default LearningPaths;
