import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Modal as BootstrapModal, Form, Button } from 'react-bootstrap';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import { format, parse, startOfWeek, getDay } from 'date-fns';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import env from 'react-dotenv';

const locales = {
  'en-US': require('date-fns/locale/en-US'),
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const SummaryOfTheDay = () => {
  const [books, setBooks] = useState([]);
  const [events, setEvents] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedBook, setSelectedBook] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    fetchBooks();
    fetchSummaries();
  }, []);

  const fetchBooks = async () => {
    try {
      const response = await axios.get(env.API_URL + '/books');
      setBooks(response.data);
    } catch (error) {
      console.error('Error fetching books:', error);
    }
  };

  const fetchSummaries = async () => {
    try {
      const response = await axios.get(env.API_URL + '/summaries');
      const summaries = response.data.map((summary) => ({
        ...summary,
        start: new Date(summary.date),
        end: new Date(summary.date),
        title: summary.book.title,
      }));
      setEvents(summaries);
    } catch (error) {
      console.error('Error fetching summaries:', error);
    }
  };

  const handleSelectEvent = (event) => {
    setSelectedDate(event.start);
    setSelectedBook(event.book.id);
    setModalIsOpen(true);
  };

  const handleSelectSlot = ({ start }) => {
    setSelectedDate(start);
    setSelectedBook('');
    setModalIsOpen(true);
  };

  const handleSaveSummary = async () => {
    try {
      await axios.post(env.API_URL + '/summaries', { date: selectedDate, bookId: selectedBook });
      fetchSummaries();
      setModalIsOpen(false);
    } catch (error) {
      console.error('Error saving summary:', error);
    }
  };

  return (
    <Container className="mt-5">
      <h1 className="mb-4">Définir le Résumé du Jour</h1>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        selectable
        onSelectEvent={handleSelectEvent}
        onSelectSlot={handleSelectSlot}
      />
      <BootstrapModal show={modalIsOpen} onHide={() => setModalIsOpen(false)}>
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>Définir le Résumé du Jour</BootstrapModal.Title>
        </BootstrapModal.Header>
        <BootstrapModal.Body>
          <Form>
            <Form.Group controlId="formBookSelect">
              <Form.Label>Sélectionner un Livre</Form.Label>
              <Form.Control
                as="select"
                value={selectedBook}
                onChange={(e) => setSelectedBook(e.target.value)}
              >
                <option value="">Sélectionner un livre</option>
                {books.map((book) => (
                  <option key={book.id} value={book.id}>
                    {book.title} - {book.author}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form>
        </BootstrapModal.Body>
        <BootstrapModal.Footer>
          <Button variant="secondary" onClick={() => setModalIsOpen(false)}>
            Annuler
          </Button>
          <Button variant="primary" onClick={handleSaveSummary}>
            Enregistrer
          </Button>
        </BootstrapModal.Footer>
      </BootstrapModal>
    </Container>
  );
};

export default SummaryOfTheDay;
