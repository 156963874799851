import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import MdEditor from 'react-markdown-editor-lite';
import MarkdownIt from 'markdown-it';
import 'react-markdown-editor-lite/lib/index.css';
import {Button, Form, Table, Container, Row, Col, Modal as BootstrapModal, Spinner, Pagination} from 'react-bootstrap';
import './App.css';
import env from 'react-dotenv'
import {Link} from "react-router-dom";

const mdParser = new MarkdownIt();

const Books = () => {
  const [books, setBooks] = useState([]);
  const [newBook, setNewBook] = useState({ title_fr: '', author: '', categories: '', thumbnail: '', text_fr: '', status: 'À créer' });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editBook, setEditBook] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterCategories, setFilterCategories] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [saveSpinnerHide, setSaveSpinnerHide] = useState(true);
  const [chatGPTSpinnerHide, setChatGPTSpinnerHide] = useState(true);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const [currentPage, setCurrentPage] = useState(1);
  const booksPerPage = 10;
  const [previousPaginationDisabled, setPreviousPaginationDisabled] = useState(true);
  const [nextPaginationDisabled, setNextPaginationDisabled] = useState(true);

  const fetchBooks = useCallback(async () => {
    try {
      const response = await axios.get(env.API_URL + '/books')
      setBooks(response.data);
      paginate(1)
      handleSort("title_fr")
    } catch (error) {
      console.error('Error fetching books:', error);
    }
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchBooks();

  }, [fetchBooks]);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewBook({ ...newBook, [name]: value });
  };

  const handleAddBook = async () => {
    try {
      await axios.post(env.API_URL + '/books', newBook).then(r => {
        setBooks([...books, r.data]);
        setNewBook({ title_fr: '', author: '', categories: '', thumbnail: '', text_fr: '', status: 'À créer' });
      });
    } catch (error) {
      console.error('Error adding book:', error);
    }
  };

  const openModal = (book) => {
    setEditBook(book);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setEditBook(null);
  };

  const handleEditChange = ({ html, text }) => {
    const text_fr = text;
    setEditBook({ ...editBook, text_fr });
  };

  const handleSaveEdit = async () => {
    setSaveSpinnerHide(false);
    try {
      const response = await axios.put(env.API_URL + `/books/${editBook.id}`, editBook);
      setBooks(books.map((book) => (book.id === editBook.id ? response.data : book)));
      closeModal();
    } catch (error) {
      console.error('Error updating book:', error);
    }
    setSaveSpinnerHide(true);
  };

  const handleDeleteBook = async (id) => {
    try {
      await axios.delete(env.API_URL + `/books/${id}`);
      setBooks(books.filter((book) => book.id !== id));
    } catch (error) {
      console.error('Error deleting book:', error);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCategoriesFilterChange = (e) => {
    setFilterCategories(e.target.value);
  };

  const handleStatusFilterChange = (e) => {
    setFilterStatus(e.target.value);
  };

  const generateSummaryWithChatGPT = async () => {
    setChatGPTSpinnerHide(false);
    if (editBook) {
      try {
        const response = await axios.post(env.API_URL + `/books/generate-summary`, { title_fr: editBook.title_fr, text_fr: editBook.text_fr });
        const { text_fr } = response.data;
        setEditBook({ ...editBook, text_fr });
      } catch (error) {
        console.error('Error generating summary:', error);
        setEditBook({ ...editBook, text_fr: "Erreur en générant le résumé" });
      }
    }
    setChatGPTSpinnerHide(true);
  };

  const handleSort = (field) => {
    const order = (sortField === field && sortOrder === 'asc') ? 'desc' : 'asc';
    setSortField(field);
    setSortOrder(order);
  };

  const sortedBooks = [...books].sort((a, b) => {
    if (a[sortField] < b[sortField]) return sortOrder === 'asc' ? -1 : 1;
    if (a[sortField] > b[sortField]) return sortOrder === 'asc' ? 1 : -1;
    return 0;
  });

  const filteredBooks = sortedBooks
    .filter((book) =>
      (searchTerm ? book.title_fr.toLowerCase().includes(searchTerm.toLowerCase()) ||
        book.author.toLowerCase().includes(searchTerm.toLowerCase()) ||
        book.categories.toLowerCase().includes(searchTerm.toLowerCase()) : true)
    )
    .filter((book) => (filterCategories ? book.categories === filterCategories : true))
    .filter((book) => (filterStatus ? book.status === filterStatus : true));

  const indexOfLastBook = currentPage * booksPerPage;
  const indexOfFirstBook = indexOfLastBook - booksPerPage;
  const currentBooks = filteredBooks.slice(indexOfFirstBook, indexOfLastBook);
  const totalPages = Math.ceil(filteredBooks.length / booksPerPage);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    pageNumber <= 1 ? setPreviousPaginationDisabled(true) : setPreviousPaginationDisabled(false);
    pageNumber === totalPages ? setNextPaginationDisabled(true) : setNextPaginationDisabled(false);
  }

  const renderSortIcon = (field) => {
    if (sortField !== field) return null;
    return sortOrder === 'asc' ? ' ▲' : ' ▼';
  };

  return (
    <Container className="mt-5">
      <h1 className="mb-4">Liste de Livres</h1>
      <Row className="mb-4">
        <Col>
          <Form.Control
            type="text"
            placeholder="Rechercher un livre..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </Col>
        <Col>
          <Form.Select as="select" value={filterCategories} onChange={handleCategoriesFilterChange}>
            <option value="">Toutes les catégories</option>
            {Array.from(new Set(books.map((book) => book.categories))).map((categories, index) => (
              <option key={index} value={categories}>
                {categories}
              </option>
            ))}
          </Form.Select>
        </Col>
        <Col>
          <Form.Select as="select" value={filterStatus} onChange={handleStatusFilterChange}>
            <option value="">Tous les statuts</option>
            <option value="À créer">À créer</option>
            <option value="En cours">En cours</option>
            <option value="À relire">À relire</option>
            <option value="Terminé">Terminé</option>
          </Form.Select>
        </Col>
      </Row>
      <div className="float-start col-12">
        <Pagination className="float-start col-6">
          <Pagination.Prev onClick={() => paginate(currentPage-1)} disabled={previousPaginationDisabled}/>
          {Array.from({ length: totalPages }, (_, i) => (
            <Pagination.Item key={i + 1} active={i + 1 === currentPage} onClick={() => paginate(i + 1)}>
              {i + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next onClick={() => paginate(currentPage+1) } disabled={nextPaginationDisabled}/>
        </Pagination>
        <p className="float-start d-block col-6 text-end">{books.length} Résumés disponibles</p>
      </div>
      <Table striped bordered hover>
        <thead>
        <tr>
          <th onClick={() => handleSort('title_fr')} className="pe-auto">Titre {renderSortIcon('title_fr')}</th>
          <th onClick={() => handleSort('author')} className="pe-auto">Auteur {renderSortIcon('author')}</th>
          <th>Catégorie</th>
          <th>Thumbnail</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>
            <Form.Control
              type="text"
              name="title_fr"
              value={newBook.title_fr}
              onChange={handleInputChange}
              placeholder="Titre"
            />
          </td>
          <td>
            <Form.Control
              type="text"
              name="author"
              value={newBook.author}
              onChange={handleInputChange}
              placeholder="Auteur"
            />
          </td>
          <td>
            <Form.Control
              type="text"
              name="categories"
              value={newBook.categories}
              onChange={handleInputChange}
              placeholder="Catégorie"
            />
          </td>
          <td>
            <Form.Control
              type="text"
              name="thumbnail"
              value={newBook.thumbnail}
              onChange={handleInputChange}
              placeholder="URL de la miniature"
            />
          </td>
          <td>
            <Form.Select as="select" name="status" value={newBook.status} onChange={handleInputChange}>
              <option value="À créer">À créer</option>
              <option value="En cours">En cours</option>
              <option value="À relire">À relire</option>
              <option value="Terminé">Terminé</option>
            </Form.Select>
          </td>
          <td>
            <Button variant="success" onClick={handleAddBook}>➕</Button>
          </td>
        </tr>
        {currentBooks.map((book) => (
          <tr key={book.id}>
            <td className="fw-bold"><Link role="button" href="" className="pe-auto" onClick={() => openModal(book)}>{book.title_fr}</Link></td>
            <td>{book.author}</td>
            <td>{book.categories}</td>
            <td><img src={book.thumbnail} alt={book.title} className="thumbnail-image" /></td>
            <td>{book.status}</td>
            <td>
              <Button variant="warning" onClick={() => openModal(book)} className="me-2">✏️</Button>
              <Button variant="danger" onClick={() => handleDeleteBook(book.id)}>❌</Button>
            </td>
          </tr>
        ))}
        </tbody>
      </Table>

      <div className="float-start col-12">
        <Pagination className="float-start col-6">
          <Pagination.Prev onClick={() => paginate(currentPage-1)} disabled={previousPaginationDisabled}/>
          {Array.from({ length: totalPages }, (_, i) => (
            <Pagination.Item key={i + 1} active={i + 1 === currentPage} onClick={() => paginate(i + 1)}>
              {i + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next onClick={() => paginate(currentPage+1) } disabled={nextPaginationDisabled}/>
        </Pagination>
        <p className="float-start d-block col-6 text-end">{books.length} Résumés disponibles</p>
      </div>

      <BootstrapModal show={modalIsOpen} onHide={closeModal} size="lg">
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>Modifier le Livre</BootstrapModal.Title>
        </BootstrapModal.Header>
        <BootstrapModal.Body>
          {editBook && (
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Titre</Form.Label>
                <Form.Control
                  type="text"
                  name="title_fr"
                  value={editBook.title_fr}
                  onChange={(e) => setEditBook({ ...editBook, title_fr: e.target.value })}
                  placeholder="Titre"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Auteur</Form.Label>
                <Form.Control
                  type="text"
                  name="author"
                  value={editBook.author}
                  onChange={(e) => setEditBook({ ...editBook, author: e.target.value })}
                  placeholder="Auteur"
                />
              </Form.Group>
              <Button variant="primary" onClick={generateSummaryWithChatGPT} className="mt-2">
                Créer le résumé avec ChatGPT  <Spinner size="sm" as="span" hidden={chatGPTSpinnerHide} animation="grow"></Spinner>
              </Button>
              <Form.Group className="mb-3">
                <Form.Label>Texte</Form.Label>
                <MdEditor
                  value={editBook.text_fr}
                  style={{ height: '500px' }}
                  renderHTML={(text) => mdParser.render(text)}
                  onChange={handleEditChange}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Catégorie</Form.Label>
                <Form.Control
                  type="text"
                  name="categories"
                  value={editBook.categories}
                  onChange={(e) => setEditBook({ ...editBook, categories: e.target.value })}
                  placeholder="Catégorie"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>URL de la miniature</Form.Label>
                <Form.Control
                  type="text"
                  name="thumbnail"
                  value={editBook.thumbnail}
                  onChange={(e) => setEditBook({ ...editBook, thumbnail: e.target.value })}
                  placeholder="URL de la miniature"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Status</Form.Label>
                <Form.Select as="select" name="status" value={editBook.status} onChange={(e) => setEditBook({ ...editBook, status: e.target.value })}>
                  <option value="À créer">À créer</option>
                  <option value="En cours">En cours</option>
                  <option value="À relire">À relire</option>
                  <option value="Terminé">Terminé</option>
                </Form.Select>
              </Form.Group>
            </Form>
          )}
        </BootstrapModal.Body>
        <BootstrapModal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Annuler
          </Button>

          <Button variant="primary" onClick={handleSaveEdit}>
            Enregistrer <Spinner size="sm" as="span" hidden={saveSpinnerHide} animation="grow"></Spinner>
          </Button>
        </BootstrapModal.Footer>
      </BootstrapModal>
    </Container>
  );
};

export default Books;
